@import '../../styles/variables';

.logo-form-container {
  width: 55%;
  height: 100vh;
  padding: 80px 40px 40px 40px;

  .logo {
    width: 100%;
    max-width: 300px;
    margin: calc(11vh - 40px) auto 20px;

    img {
      max-width: 200px;
    }
  }

  .form-container {
    font-size: 14px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto 8px;
  }

  .login-btn {
    width: 100%;
    max-width: 428px;
    background-color: $primary;
    border-color: $primary;
  }

  .input-container {
    position: relative;
    min-height: 40px;
    height: 40px;
    max-height: 40px;
  }

  .input-container {
    input {
      min-height: 40px;
      height: 40px;
      max-height: 40px;
      padding: 4px 12px 4px 38px;
      background-color: $white;

      &:focus {
        outline: none;
        border-color: $primary;
        box-shadow: none;
      }

      &::placeholder {
        color: $placeholder;
      }
    }

    .icon {
      position: absolute;
      left: 12px;
      top: 10px;
      z-index: 7;
      color: $placeholder;
    }
  }

}


@media (min-width: 960px) {
  .logo-form-container {
    width: 55%;
    padding: 80px 40px 40px 40px;

    .logo {
      margin-bottom: 40px;
    }

    .welcome {
      font-size: 23px;
      font-weight: 700;
      margin-bottom: 24px;
    }

    .form-container {
      margin: 0 auto 16px;
    }

    .login-btn {
      margin-top: 32px;
    }
  }
}
