@import 'src/styles/variables';

.income-report-table {
  .select-column {
    width: 35px;
    vertical-align: middle;
  }

  .ipn-column {
    width: 100px;
    vertical-align: middle;
  }

  .name-column {
    width: 25vw;
    vertical-align: middle;
  }

  .period-header-column {
    width: 25vw;
    border-width: 1px;
  }

  .tax-amount-column {
    width: 8vw;
    border-width: 1px;
  }

  .social-tax-column {
    width: 8vw;
    vertical-align: middle;
    border-width: 1px;
  }

  .registration-column {
    vertical-align: middle;
  }

  .actions-column {
    vertical-align: middle;
  }

  .receipt-success {
      color: #4ebb7b;
      vertical-align: middle;
  }

  .receipt-error {
    color: #fb3f4c;
    vertical-align: middle;
  }

}
