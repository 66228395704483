@import 'src/styles/variables';

.income-report-table {
  .select-column {
    width: 35px;
  }

  .ipn-column {
    width: 100px;
  }

  .name-column {
    width: 25vw;
  }

  .actions-column {
    min-width: 120px;
  }

  .receipt-success {
      color: #4ebb7b;
  }

  .receipt-error {
    color: #fb3f4c;
  }

}
