@import '../../styles/variables';

.accountEdit{
  margin-top: 40px;
  .form-label {
    margin: unset;
    color: $label;
    font-weight: bold;
    font-size: small;

  }

  .account-name {
    font-weight: normal;
    color: unset;
    padding-bottom: 20px;
  }

  .form-group {
    .form-control {
      padding: unset;
      border: 0;
      outline: none;
      box-shadow: none;
      font-size: medium;
      font-weight: 500;
    }
    .address {
      height: 72px;
    }
  }

  .add-button {
    padding-left: 15px;
    color: $primary;
    font-size: small;

    &:hover{
      color: $secondary;
      font-size: small + 5px;
    }
  }
}
