
.debt-table-row-item{
  .sti-name-column {
    width: 10vw ;
  }

  .name-plt-column {
    width: 50vw;
  }

  .tax-amount-column {
    width: 50px;
  }

  .notification-column {
    width: 20vw;
  }

}