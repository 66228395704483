.main-navbar {
  background-color: #F1F1E6;
  .navbar-brand {
    padding-left: 24px;

    .logo {
      max-width: 200px;
      width: 120px;
    }
  }

  .user-item {
    padding-right: 30px;
  }

  .navbar-nav {
   .nav-link {
      width: 160px;
      font-weight: 500;
    }
    .nav-link.active {
      background-color: #F1F1E6;
      font-weight: 900;
    }

  }

}

