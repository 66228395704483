@import 'src/styles/variables';

.ui-jdm {
   .td_unln {
    border: solid #000;
    border-width: 0 0 1px
  }

  .td_unln_light {
    border: solid #b6b6b6;
    border-width: 0 0 1px
  }

  .dm-cell-val {
    padding: 0!important;
    min-height: 36px!important
  }

  .tmplPageDoc .ui-state-highlight {
    background: #fcd38b!important
  }

  .tmplPageDoc {
    font-size: 13px!important;
    font-family: Helvetica,Courier,Tahoma,Arial!important;
    border-collapse: collapse;
    padding: 0;
    border-width: 0;
    margin: 0;
  }

  .tmplKvt {
    border: solid #b6b6b6;
    border-width: 0 1px 1px

  }

  .tmplPageDoc tr {
    min-height: .0001in;
    overflow: visible
  }

  .tmplPageDoc td {
    border-spacing: 0;
    overflow: visible
  }

  .dm-field-noupdate{
    font-weight: bold;
  }
}

.modal-90w{
  width: 90vw;
  max-width: 1500px;
  min-width: 1024px;
}
