@import 'src/styles/variables';

$theme-colors: (
    primary: $primary,
    secondary: $secondary,
    success: $success,
    reject: $reject,
    danger: $danger,
    warning: $warning
);

.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #fff !important;
  background-color: $danger !important;
  border-color: $danger !important;
}

.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: #fff !important;
  background-color: $warning !important;
  border-color: $warning !important;
}

tbody {
  font-size: small;
}



@import '../../node_modules/bootstrap/scss/bootstrap';
