@import 'src/styles/variables';

.debt-table {
  .select-column {
    width: 35px;
  }

  .ipn-column {
    width: 100px;
  }

  .name-column {
    width: 25vw;
  }

  .actions-column {
    min-width: 100px;
  }

  .receipt-success {
    color: #4ebb7b;
  }

  .receipt-error {
    color: #fb3f4c;
  }

  .sti-name-column {
    width: 300px;
  }

  .tax-amount-column {
    width: 200px;
    font-weight: 700;
  }

  .tax-amount-column[data-value="debt"]{
    color: #fb3f4c;
  }

  .tax-amount-column[data-value="overpayment"]{
    color: #4ebb7b;
  }

  .debt-list-table{
    margin-bottom: 0;
  }
;

}