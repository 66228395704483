@import '../../styles/variables';

.account{
}


.add-button {
  color: $white!important;
  float: right;
}

.account-table {
  padding: 0 16px;
}

.search-container {
  input {
    min-height: 40px;
    height: 40px;
    max-height: 40px;
    padding: 4px 8px;
    background-color: $white;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: $placeholder;
    }
  }

  .search-input {
    border-radius: 0.375rem!important;
    padding-right: 30px;
  }

  .icon {
    color: $placeholder;
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 7;
  }

}
