$primary: #233565;
$secondary: #000131;
$white: #ffffff;
$placeholder: #b4b9d0;
$label: #8b8c8d;
$success: #8BD8BC;
$reject: #BFA5A6;
$danger: #dc3545;
$warning: #c4750b;

