@import '../../styles/variables';

.send-notification-modal{
  .form-label {
    margin: unset;
    color: $label;
    font-weight: bold;
    font-size: small;
  }

  .form-group {
    .form-text {
      padding: unset;
      border: 0;
      outline: none;
      box-shadow: none;
      font-size: medium;
      font-weight: 500;
      display: block;
      color: var(--bs-body-color);
    }

    .input-container {
      position: relative;
      min-height: 40px;
      height: 40px;
      max-height: 40px;
    }

    .input-container {
      input {
        min-height: 40px;
        height: 40px;
        max-height: 40px;
        padding: 4px 12px 4px 38px;
        background-color: $white;

        &:focus {
          outline: none;
          border-color: $primary;
          box-shadow: none;
        }

        &::placeholder {
          color: $placeholder;
        }
      }
    }

  }
}
